<template>
  <div class="page">
    <div class="mind" v-if="enterMap.length">
      <dl
        class="enters"
        v-for="item in enterMap"
        :key="item.id"
        :class="item.enters.length > 9 ? 'enters_row' : ''"
      >
        <dt class="enter title border_none">
          <span>{{ item.name }}</span>
          <el-image style="width: 22px; height: 22px; margin-left: 4px" :src="item.img" />
        </dt>
        <div class="menu_sec">
          <dd
            class="enter"
            v-for="temp in item.enters"
            :key="temp.code"
            @click="goLotteryPage(temp)"
          >
            <dt class="enter">
              <span>{{ temp.name }}</span>
            </dt>
            
          </dd>
        </div>
      </dl>
    </div>
  </div>
</template>

<script setup scoped>
import { ref, watch } from 'vue'
import { useRouter } from 'vue-router'
import {lotteryGroupMap} from '@/core/crypto/Mapping'
import { useHomeStore } from '@/stores/home.js'
const homeStore = useHomeStore()
const router = useRouter()
const emits = defineEmits(['selected'])

import LHC from '@/assets/home/lhc.png'
import SCFT from '@/assets/home/scft.png'
import SSC from '@/assets/home/ssc.png'
import K3 from '@/assets/home/k3.png'
import Other from '@/assets/home/other.png'
const enterMap = ref([])
watch(
  () => homeStore.allLotteryObj,
  (newVal, oldVal) => {
    if (newVal) {
      enterMap.value = [
        {
          id: '0',
          name: '六合彩',
          img: LHC,
          url: '/lottery',
          enters: [...newVal.lhcList]
        },
        {
          id: '1',
          name: '赛车飞艇',
          url: '/lottery/sc',
          img: SCFT,
          enters: [...newVal.scList]
        },
        {
          id: '2',
          name: '时时彩',
          url: '/lottery/ssc',
          img: SSC,
          enters: [...newVal.sscList]
        },
        {
          id: '3',
          name: '快三',
          url: '/lottery/k3',
          img: K3,
          enters: [...newVal.k3List]
        },
        {
          id: '4',
          name: '其他',
          url: '/lottery',
          img: Other,
          enters: [...newVal.otherList]
        }
      ]
    }
  },
  {
    deep: true,
    immediate: true
  }
)

// 前往彩票投注页
function goLotteryPage(temp) {
  const { code, type } = temp
  emits('selected')
  router.push({
    path: `/lottery/${lotteryGroupMap[type].toLowerCase()}`,
    query: { lotteryCode: code }
  })
}
</script>

<style lang="scss" scoped>
.page {
  @include flexBetween;
  background-color: #fff;
}

.mind {
  display: flex;
  justify-content: center;
  padding: 10px 0;
}

.enters {
  width: 130px;

  .border_none {
    border: none !important;
  }

  &:first-child .menu_sec {
    border-left: 0;
  }

  .menu_sec {
    border-left: 1px solid #f5f5f5;

    .enter {
      height: 40px;
      @include flexCenter;
      cursor: pointer;
      font-size: 16px;
      color: #5f5f5f;
      line-height: 1;
      overflow: hidden;
      transition: transform .2s;

      &:hover {
        background-color: #f5f5f5;
        dt {
          transform: translateX(5px);
        }
      }
      &:active {
        background-color: #f8faff;
        color: $primaryColor;
      }
    }
  }

  .title {
    height: 50px;
    line-height: 50px;
    font-size: 18px;
    color: #999;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.enters_row {
  width: 260px;

  .menu_sec {
    display: flex;
    flex-wrap: wrap;
    width: 100%;

    .enter {
      width: 129px;
    }
  }
}
</style>
